import React from "react";
import ContextProvider from "../context/ContextProvider";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
} from "@apollo/client";
import fetch from "isomorphic-fetch";

// const token = process.env.CONTENTFUL_ACCESS_TOKEN;
// const space_id = process.env.CONTENTFUL_SPACE_ID;

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: createHttpLink({
    fetch,
    uri: `https://graphql.contentful.com/content/v1/spaces/rtuukkt2fs3w/environments/master?access_token=YzFjHdyq1A60nGBFQOv34vAjxW8GVdKSFkwIDU3YfyQ`,
  }),
});

export default ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <ContextProvider>{element}</ContextProvider>
    </ApolloProvider>
  );
};
