import React, { useEffect, useState } from "react";
import { DebrisTrackerContext } from "./DebrisTrackerContext";

const ContextProvider = ({ children }) => {
  const [user, setUser] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    let userData = sessionStorage.getItem("user");
    if (userData && !user.id) {
      setLoading(false);
      setUser(JSON.parse(userData));
    }
    setLoading(false);
  }, [user]);

  const initialState = {
    user,
    setUser,
    loading,
  };

  return (
    <DebrisTrackerContext.Provider value={initialState}>
      {children}
    </DebrisTrackerContext.Provider>
  );
};

export default ContextProvider;
