import { createContext } from "react";
import { ListType } from "../types/list";
import { User } from "../types/user";

interface DebrisTrackerContextType {
  user?: User;
  setUser?: (user: any) => void;

  lists?: ListType[];
  setLists?: (l: ListType[]) => void;

  loading?: boolean;
}

export const DebrisTrackerContext = createContext<DebrisTrackerContextType>({});
